import React from "react";
import classes from "./Button.module.scss";
import { useParams, Link } from "react-router-dom";

const Button = ({children, login, href}) => {
  const { lang } = useParams();
  return (
    <>
      {login ?
        <a href="https://user.atlas-finance.site/login" className={`${classes.button} hoverElement`}>
          <span>{children}</span>
        </a>
        :
        <Link
          to={!href ? 'https://user.atlas-finance.site/signup' : `/${lang}/${href}`} 
          className={`${classes.button} hoverElement`}
        >
          <span>{children}</span>
        </Link>
      }
    </>
  );
};

export default Button;
